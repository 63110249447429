import React, { useState, useEffect } from "react";
import Loader from "components/loader";
import { Button } from "components/ui/button";
import { Trash2, Download, Loader2, User, Clock } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useToast } from "components/ui/use-toast";
import supabase from "utils/supabaseClient";
import Lightbox from "./lightbox";
import {
	AlertDialog,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { useGuest } from "hooks/useGuest";
import styled from "styled-components";

const MotionLightbox = styled.div`
	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
	padding-top: 24px;
`;

const MotionBackdrop = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
`;

const MotionImageWrapper = styled(motion.div)`
	margin: auto;
	border-radius: 8px;
	overflow: hidden;
	max-width: 90%;
	max-height: 90%;
	min-width: 100px;
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;

	.information {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 100px 12px 12px 12px;
		background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
		color: white;
		font-size: 11px;
		line-height: 1em;
		display: flex;
		align-items: center;
		gap: 16px;

		li {
			display: flex;
			align-items: center;
		}
	}

	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const MotionImage = styled(motion.img)`
	pointer-events: none;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Actions = styled(motion.div)`
	display: flex;
	justify-content: center;
	gap: 12px;
	margin-top: 24px;
	margin-bottom: 24px;
`;

function Image({ photo, onDelete }) {
	const { toast } = useToast();
	const [isExpanded, setIsExpanded] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDoneLoading, setIsDoneLoading] = useState(false);
	const [showButtons, setShowButtons] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const { guest } = useGuest();
	const { guest: uploader } = useGuest(photo.guest_id);

	const handleDelete = async () => {
		try {
			setIsDeleting(true);

			// Delete the corresponding row from the Uploads table
			await supabase
				.from("Uploads")
				.delete()
				.eq("photo_id", photo.photo_id);

			// Update the guest's upload count
			const guestId = photo.guest_id;

			const { data: guestData, error: guestError } = await supabase
				.from("Guests")
				.select("uploads")
				.eq("id", guestId)
				.single();

			if (guestError) {
				console.error(
					"Error retrieving guest data:",
					guestError.message
				);
			} else {
				const updatedUploadCount = guestData.uploads - 1;

				const { error: updateError } = await supabase
					.from("Guests")
					.update({ uploads: updatedUploadCount })
					.eq("id", guestId);

				if (updateError) {
					console.error(
						"Error updating guest upload count:",
						updateError.message
					);
				}
			}

			// Call the onDelete callback to remove the image from the Gallery component state
			onDelete(photo.photo_id);

			toast({
				title: "Slika je obrisana! 😭",
			});

			setIsDeleting(false);
		} catch (error) {
			console.error("Error deleting image:", error.message);
			setIsDeleting(false);
		}
	};

	useEffect(() => {
		if (isExpanded) {
			document.body.style.overflow = "hidden";

			setShowLoader(false); // Hide loader initially
			// Show loader after 500ms
			setTimeout(() => setShowLoader(true), 500);
			setTimeout(() => setShowButtons(true), 250);
		} else {
			document.body.style.overflow = "auto";
		}

		// Clean up function
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isExpanded]);

	return (
		<>
			<motion.img
				src={photo.thumbnail}
				alt=""
				loading="lazy"
				whileTap={{ scale: 0.9 }}
				onClick={() => setIsExpanded(!isExpanded)}
				style={{ borderRadius: "6px" }}
			/>

			<AnimatePresence>
				{isExpanded && (
					<Lightbox>
						<MotionLightbox>
							<MotionBackdrop
								onClick={() => setIsExpanded(!isExpanded)}
								initial={{ opacity: 0 }} // initial state
								animate={{ opacity: 1 }} // animate to
								exit={{ opacity: 0 }} // animate out
								transition={{ duration: 0.3 }} // transition options
								className="backdrop"
							/>
							<MotionImageWrapper
								initial={{
									opacity: 0,
									scale: 0.75,
									transition: {
										duration: 0.2,
									},
								}}
								animate={{
									opacity: 1,
									scale: 1,
									transition: {
										duration: 0.2,
										delay: 0.2,
									},
								}}
								exit={{
									opacity: 0,
									scale: 0.5,
									transition: {
										opacity: {
											duration: 0.2,
										},
										transform: { duration: 0.15 },
									},
								}}
								drag="y" // enable drag on y axis
								dragConstraints={{ top: 0, bottom: 250 }} // limit drag to 50px down
								dragElastic={0.5} // add some elasticity to the drag
								dragTransition={{
									type: "spring",
									stiffness: 300,
									damping: 30,
								}} // spring physics for the drag
								whileDrag={{ scale: 0.9 }} // scale down to 95% while dragging
								onDragStart={() => setShowButtons(false)}
								onDragEnd={(e, { offset, velocity }) => {
									// if dragged down fast enough or far enough, setIsExpanded to false
									if (velocity.y > 1000 || offset.y > 100) {
										setIsExpanded(false);
										setIsDoneLoading(false);
									} else {
										setShowButtons(true);
									}
								}}
							>
								{showLoader && <Loader />}

								<MotionImage
									src={photo.url}
									initial={{ opacity: 0, scale: 0.8 }}
									animate={
										isDoneLoading
											? { opacity: 1, scale: 1 }
											: { opacity: 0, scale: 0.8 }
									}
									transition={{ duration: 0.25 }}
									onLoad={() => setIsDoneLoading(true)}
								/>

								<motion.ul
									className="information"
									initial={{
										opacity: 0,
									}}
									animate={
										showButtons && isDoneLoading
											? {
													opacity: 1,
											  }
											: {
													opacity: 0,
											  }
									}
									transition={{ duration: 0.25 }}
								>
									<motion.li
										initial={{
											transform: "translateY(50px)",
										}}
										animate={
											showButtons && isDoneLoading
												? {
														transform:
															"translateY(0px)",
												  }
												: {
														transform:
															"translateY(50px)",
												  }
										}
										transition={{
											duration: 0.3,
											delay: 0.4,
										}}
									>
										<User className="mr-1 h-3 w-3" />{" "}
										{uploader.first_name}{" "}
										{uploader.last_name}
									</motion.li>
									<motion.li
										initial={{
											transform: "translateY(50px)",
										}}
										animate={
											showButtons && isDoneLoading
												? {
														transform:
															"translateY(0px)",
												  }
												: {
														transform:
															"translateY(50px)",
												  }
										}
										transition={{
											duration: 0.3,
											delay: 0.5,
										}}
									>
										<Clock className="mr-1 h-3 w-3" />{" "}
										{new Date(
											photo.created_at
										).toLocaleTimeString([], {
											hour: "2-digit",
											minute: "2-digit",
										})}
									</motion.li>
								</motion.ul>
							</MotionImageWrapper>

							<Actions
								initial={{
									scale: 0.5,
									transform:
										"translateY(200px) rotate(50deg)",
								}}
								animate={
									showButtons && isDoneLoading
										? {
												scale: 1,
												transform:
													"translateY(0px) rotate(0deg)",
										  }
										: {
												scale: 0.5,
												transform:
													"translateY(200px) rotate(50deg)",
										  }
								}
								transition={{ duration: 0.3 }}
							>
								<Button asChild>
									<a
										href={photo.url.replace(
											/(\.jpg|\.jpeg|\.png)$/,
											"-original$1"
										)}
										className="href"
										download
									>
										<Download className="mr-2 h-4 w-4" />
										Preuzmi sliku
									</a>
								</Button>
								{(photo.guest_id === guest.id ||
									guest.role === "admin") && (
									<AlertDialog>
										<Button asChild variant="destructive">
											<AlertDialogTrigger>
												<Trash2 className="mr-2 h-4 w-4" />
												Obriši sliku
											</AlertDialogTrigger>
										</Button>
										<AlertDialogContent>
											<AlertDialogHeader>
												<AlertDialogTitle>
													Da li ste sigurni da želite
													da obrišete ovu sliku?
												</AlertDialogTitle>
											</AlertDialogHeader>
											<AlertDialogFooter>
												<AlertDialogCancel>
													Ne, vrati me nazad.
												</AlertDialogCancel>
												<Button
													variant="destructive"
													onClick={handleDelete}
												>
													{isDeleting ? (
														<Loader2 className="mr-2 h-4 w-4 animate-spin" />
													) : (
														<Trash2 className="mr-2 h-4 w-4" />
													)}
													{isDeleting
														? "Brišem..."
														: "Da, obriši je..."}
												</Button>
											</AlertDialogFooter>
										</AlertDialogContent>
									</AlertDialog>
								)}
							</Actions>
						</MotionLightbox>
					</Lightbox>
				)}
			</AnimatePresence>
		</>
	);
}

export default Image;
