import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "styles/index.scss";
import { LogOut } from "lucide-react";
import Cookies from "js-cookie";
import supabase from "utils/supabaseClient";

import { useGuest } from "hooks/useGuest";
import Loader from "components/loader";
import { Toaster } from "components/ui/toaster";
import Navigation from "components/navigation";
import Login from "components/Login";
import Home from "pages/home";
import Slike from "pages/slike";
import Satnica from "pages/satnica";
import Slideshow from "pages/slideshow";

const Transition = ({ children }) => {
	return (
		<motion.div
			initial={{
				opacity: 0,
				transform: "translateY(-20px) ",
			}}
			animate={{
				opacity: 1,
				transform: "translateY(0px) ",
			}}
			exit={{
				opacity: 0,
				transform: "translateY(20px) ",
			}}
			transition={{ duration: 0.25 }}
		>
			{children}
		</motion.div>
	);
};

function AppRoutes() {
	const location = useLocation(); // This is now within the context of <Router>
	const { guest } = useGuest();

	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname}>
				<Route
					path="/"
					element={
						<Transition>
							<Home guest={guest} />
						</Transition>
					}
				/>
				<Route
					path="/slike"
					element={
						<Transition>
							<Slike guest={guest} />
						</Transition>
					}
				/>
				<Route
					path="/satnica"
					element={
						<Transition>
							<Satnica />
						</Transition>
					}
				/>
				<Route path="/slideshow" element={<Slideshow />} />
			</Routes>
		</AnimatePresence>
	);
}

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const { guest, setGuestId } = useGuest();

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsLoading(false);
		}, 500);

		return () => clearTimeout(timeout);
	}, []);

	const handleLogout = async () => {
		Cookies.remove("user");
		setGuestId(null);

		const { error } = await supabase
			.from("Guests")
			.update({ logged_in: false })
			.eq("id", guest.id);

		if (error) {
			console.error("Error updating logged_in:", error.message);
		}

		window.location.reload();
	};

	return (
		<div className="outro-wrapper">
			<img src="/svadba.jpg" alt="Header" className="outro-image" />

			{/* <Router>
				<Toaster />
				{guest.id && <Navigation />}

				{guest.id && (
					<button className="logout" onClick={handleLogout}>
						<LogOut />
					</button>
				)}

				<div className="header">
					<img
						src="/header2.jpg"
						alt="Header"
						className="header-image"
					/>
				</div>
				<div className="page-wrapper -mt-8 p-4 pb-24">
					{isLoading ? (
						<Loader isFullWidth />
					) : guest.id ? (
						<AppRoutes />
					) : (
						<Login onSelect={(x) => setGuestId(x)} />
					)}
				</div>
			</Router> */}
		</div>
	);
}

export default App;
