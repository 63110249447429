import React from "react";
import styled from "styled-components";

const Container = styled.div`
	--uib-size: 70px;
	--uib-color: var(--colors-primary);
	--uib-speed: 1.65s;
	--uib-cube-size: calc(var(--uib-size) / 5.5);
	--uib-arc-1: -80deg;
	--uib-arc-2: 80deg;
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${(props) => (props.isFullWidth ? "100%" : "var(--uib-size)")};
	height: ${(props) =>
		props.isFullWidth ? "150px" : "calc(var(--uib-size) * 0.51)"};
`;

const Cube = styled.div`
	width: var(--uib-cube-size);
	height: calc(var(--uib-size) * 0.5);
	transform-origin: center bottom;
	transform: rotate(var(--uib-arc-1));
	animation: metronome var(--uib-speed) linear infinite;

	&::after {
		content: "";
		display: block;
		width: var(--uib-cube-size);
		height: var(--uib-cube-size);
		background-color: var(--uib-color);
		border-radius: 25%;
		animation: morph var(--uib-speed) linear infinite;
		transition: background-color 0.3s ease;
	}

	@keyframes metronome {
		0% {
			transform: rotate(var(--uib-arc-1));
		}
		5% {
			transform: rotate(var(--uib-arc-1));
			animation-timing-function: ease-out;
		}
		50% {
			transform: rotate(var(--uib-arc-2));
		}
		55% {
			transform: rotate(var(--uib-arc-2));
			animation-timing-function: ease-out;
		}
		100% {
			transform: rotate(var(--uib-arc-1));
		}
	}

	@keyframes morph {
		0%,
		5% {
			transform: scaleX(0.75) scaleY(1.25);
			transform-origin: center left;
		}
		12.5% {
			transform: scaleX(1.5);
			transform-origin: center left;
		}
		27.5%,
		27.5001%,
		42.5% {
			transform: scaleX(1);
			transform-origin: center left;
		}
		50%,
		52.5% {
			transform: scaleX(0.75) scaleY(1.25);
			transform-origin: center right;
			animation-timing-function: ease-in;
		}
		65% {
			transform: scaleX(1.5);
			transform-origin: center right;
		}
		77.5%,
		77.5001%,
		95% {
			transform: scaleX(1);
			transform-origin: center right;
		}
		100% {
			transform: scaleX(0.75) scaleY(1.25);
			transform-origin: center left;
		}
	}
`;

const Loader = (props) => {
	const { isFullWidth } = props;

	return (
		<Container className="loader" $isFullWidth={isFullWidth}>
			<Cube />
		</Container>
	);
};
export default Loader;
