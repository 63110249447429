import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import Home from "icons/home";
import Timeline from "icons/timeline";
import Slideshow from "icons/slideshow";
// import Settings from "icons/settings";

const NavMotion = (props) => {
	return (
		<NavLink
			to={props.to}
			className={({ isActive }) => (isActive ? "active" : "")}
		>
			<motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.7 }}>
				{props.icon}
				{props.label}
			</motion.div>
		</NavLink>
	);
};

function Navigation() {
	return (
		<nav>
			<NavMotion to="/slike" icon={<Slideshow />} label="Galerija" />
			<NavMotion to="/" icon={<Home />} label="Zakači slike" />
			<NavMotion to="/satnica" icon={<Timeline />} label="Satnica" />
		</nav>
	);
}

export default Navigation;
