import { useState, useEffect } from "react";
import supabase from "utils/supabaseClient";
import Cookies from "js-cookie";

export const useGuest = (userID) => {
	const [guestId, setGuestId] = useState();
	const [guest, setGuest] = useState([]);

	const fetchGuest = async (guestId) => {
		const { data, error } = await supabase
			.from("Guests")
			.select("*")
			.eq("id", guestId)
			.single();

		if (error) {
			console.error("Error fetching guest:", error.message);
		} else {
			return data;
		}
	};

	useEffect(() => {
		if (guestId) {
			fetchGuest(guestId).then((data) => {
				setGuest(data);
			});
		}
	}, [guestId]);

	useEffect(() => {
		if (userID) {
			setGuestId(userID);
		} else {
			const guestId = Cookies.get("user");
			if (guestId) {
				setGuestId(guestId);
			}
		}
	}, [userID]);

	return { guest, setGuestId };
};
